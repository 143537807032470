<template>
  <v-card class="my-2 pa-2">
    <v-card-title class="text-subtitle-1 font-weight-bold d-flex align-center justify-space-between">
      Encarregado da Empresa

      <v-expand-transition>
        <v-progress-circular
          color="primary" indeterminate
          v-if="loadingInfo"
        />
      </v-expand-transition>

      <v-expand-transition>
        <v-btn
          color="info darken-2"
          small outlined text
          @click="editingInfo = true"
          v-if="!editingInfo && !loadError && !loadingInfo"
        >
          Editar Informações
        </v-btn>
      </v-expand-transition>

      <v-expand-transition>
        <v-alert v-if="loadError" color="error" dense outlined text style="margin-bottom: 0">
          <div class="d-flex justify-space-between align-center">
            <span class="text-body-2 font-weight-medium">
              Erro ao carregar Encarregado
            </span>
            <v-btn color="info" small class="ml-4" @click="getInCharge()">
              tentar novamente
            </v-btn>
          </div>
        </v-alert>
      </v-expand-transition>

    </v-card-title>
    <v-expand-transition>
      <v-alert
        v-if="inChargeIsNull && !editingInfo"
        outlined
        class="mx-4 text-justify"
        type="warning"
        color="primary"
        prominent
        border="left"
      >
        É obrigatório que a empresa indique o encarregado pela LGPD, que
        responde sobre cumprimento da lei. Cadastre o encarregado atual da
        empresa para criar requisições de consentimento.
        <br />
        <v-btn color="primary" class="mt-2" @click="editingInfo = true">
          Indicar Agora
        </v-btn>
      </v-alert>
    </v-expand-transition>

    <template v-if="!inChargeIsNull || editingInfo">
      <v-form ref="form">
        <v-card-text class="black--text">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="inCharge.name"
                label="Nome Completo *"
                :disabled="loadingSave"
                :readonly="!editingInfo" :filled="editingInfo"
                hide-details="auto"
                :rules="[$rules.required]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="inCharge.email"
                label="E-Mail *"
                :disabled="loadingSave"
                :readonly="!editingInfo" :filled="editingInfo"
                hide-details="auto"
                :rules="[$rules.required, $rules.email]"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="inCharge.cpf"
                label="CPF *"
                placeholder="000.000.000-00"
                :disabled="loadingSave"
                :readonly="!editingInfo" :filled="editingInfo"
                hide-details="auto"
                :rules="[$rules.required, $rules.cpf]"
                v-mask="['###.###.###-##']"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="inCharge.phone"
                label="Telefone *"
                placeholder="(00) 00000-0000"
                :disabled="loadingSave"
                :readonly="!editingInfo" :filled="editingInfo"
                hide-details="auto"
                :rules="[$rules.required, $rules.phone]"
                v-mask="['(##) ####-####', '(##) #####-####']"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-expand-transition>
          <v-card-actions v-if="editingInfo" class="pt-0">
            <v-spacer />
            <v-btn
              v-if="allowCancel"
              outlined
              @click="editingInfo = false, getInCharge()"
              :disabled="loadingSave"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              :loading="loadingSave"
              @click="defineNewIncharge()"
            >
              {{ saveMessage }}
            </v-btn>
          </v-card-actions>
        </v-expand-transition>
      </v-form>
    </template>
  </v-card>
</template>
<script>
export default {
  name: "InCharge",

  props: {
    mountEditingInfo: {type: Boolean, default: false},
    allowCancel: {type: Boolean, default: true},
    saveMessage: {type: String, default: "Salvar"}
  },

  data(){
    return {
      editingInfo: this.mountEditingInfo,
      loadingInfo: true,
      loadingSave: false,
      loadError: false,
      inCharge: {
        name: "",
        cpf: "",
        email: "",
        phone: "",
      },
    }
  },

  mounted() {
    this.getInCharge();
  },
  
  computed: {
    inChargeIsNull(){
      if (this.loadingInfo || this.loadError) return false
      return !this.inCharge.name
    },
  },

  methods: {
    defineNewIncharge() {
      if (this.$refs.form.validate()){
        this.loadingSave = true;
        this.$axios
          .put(`/incharge`, this.inCharge)
          .then(() => {
            this.$showMessage("success", "Encarregado Salvo");
            this.editingInfo = false
            this.getInCharge();
            this.$emit('created')
          })
          .catch(() => {
            this.$showMessage("error", "Ocorreu um erro inesperado");
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }
    },
    getInCharge() {
      this.loadingInfo = true;
      this.$axios
        .get("/incharge")
        .then((response) => {
          this.inCharge = response.data;
          this.editInChargeBtns = true;
          this.inChargeLoaded = true;
          this.$refs.form.resetValidation();
        })
        .catch(() => {
          this.loadError = true;
        })
        .finally(() => {
          this.loadingInfo = false;
        });
    },
  },
};
</script>

